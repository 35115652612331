@page {
  margin: 1cm !important;
}
body {
  font-size: $small-device-font-size;
  line-height: 1.3;
  background: #fff;
  color: #000;
}

.content {
  margin: 0 auto;
  width: 100%;
  float: none;
  display: initial;
}

.container {
  width: 100%;
  float: none;
  display: initial;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 auto;
}
img {
  max-width: 100%;
}
blockquote,
ul {
  margin: 0;
}

.sidebar {
  display: none !important;
}

.navigation {
  display: none !important;
}

.post__related {
  display: none !important;
}

.gist-meta {
  display: none !important;
}

#disqus_thread {
	display: none !important;
}

